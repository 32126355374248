
import React, {  } from 'react';

const PostBoard = () =>{
  return (
    <div className='PostBoard'>
      <h1>PostBoard</h1>
    </div>
  );
}
export default PostBoard;